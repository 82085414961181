export const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

const config = {
  basePath: process.env.BASE_PATH,
  apiUrl: process.env.REACT_APP_API_URL,
  googleAnalytics: process.env.GOOGLE_ANALYTICS,
  auth0Domain: process.env.AUTH0_DOMAIN,
  auth0ClientId: process.env.AUTH0_CLIENTID,
  auth0Audience: process.env.auth0Audience,
  fuseClassroomApiUrl: process.env.FUSE_CLASSROOM_API_URL,
}

if (!process.env.BASE_PATH && !process.env.REACT_APP_API_URL && !process.env.GATSBY_ACTIVE_ENV) {
  throw new Error(`Cannot find .env.${activeEnv} file or environment values are missing`)
}

export default config

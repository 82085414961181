import auth0 from "auth0-js"
import config from "../config"
import setStorageComponent, { getAccessToken, removeStorageComponent, getStorageComponent } from "./storage"
import { navigate } from "gatsby"

const isBrowser = typeof window !== "undefined"

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: config.auth0Domain,
      clientID: config.auth0ClientId,
      redirectUri: `${window.location.origin}/callback`,
      responseType: "token id_token",
      scope: "openid profile",
      audience: config.auth0Audience,
    })
  : {}

// insert after auth const
const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) {
    return
  }
  const accessToken = getAccessToken()
  const isLogggedIn = getStorageComponent("isLoggedIn") === "true"

  return accessToken && accessToken.length && isLogggedIn ? true : false
}

const setSession = (cb = value => {}) => (err, authResult) => {
  if (err) {
    removeSession(cb)
    navigate("/")
    cb(false)
    return
  }

  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    tokens.accessToken = authResult.accessToken
    tokens.idToken = authResult.idToken
    tokens.expiresAt = expiresAt
    user = authResult.idTokenPayload

    setStorageComponent("access_token", authResult.accessToken)
    setStorageComponent("expiresAt", expiresAt)
    setStorageComponent("idToken", authResult.idToken)
    setStorageComponent("isLoggedIn", true)

    navigate("/dashboard")
    cb(true)
  }
}

const removeSession = (cb = value => {}) => {
  removeStorageComponent("access_token")
  removeStorageComponent("expiresAt")
  removeStorageComponent("idToken")
  setStorageComponent("isLoggedIn", false)
  cb(false)
}

export const handleAuthentication = cb => {
  if (!isBrowser) {
    return
  }

  auth.parseHash(setSession())
  // navigate("/dashboard")
}

export const getProfile = () => {
  return user
}

export const silentAuth = callback => {
  if (!isAuthenticated()) return callback()

  auth.checkSession({}, setSession(callback))
}

export const login = () => {
  if (!isBrowser) {
    return
  }

  auth.authorize()
}

export const logout = () => {
  removeSession(() => {})
  auth.logout({
    clientID: config.auth0ClientId,
    returnTo: `${window.location.origin}`,
  })
}

// Helper function that will allow us to extract parameter values
export function getParameterByName(name) {
  let url_string = window.location.href
  let url = new URL(url_string)
  return url.searchParams.get(name)
}

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-callback-index-js": () => import("./../../../src/pages/callback/index.js" /* webpackChunkName: "component---src-pages-callback-index-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-request-demo-js": () => import("./../../../src/pages/dashboard/requestDemo.js" /* webpackChunkName: "component---src-pages-dashboard-request-demo-js" */),
  "component---src-pages-eula-index-js": () => import("./../../../src/pages/eula/index.js" /* webpackChunkName: "component---src-pages-eula-index-js" */),
  "component---src-pages-implementation-index-js": () => import("./../../../src/pages/implementation/index.js" /* webpackChunkName: "component---src-pages-implementation-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-install-zoom-index-js": () => import("./../../../src/pages/install_zoom/index.js" /* webpackChunkName: "component---src-pages-install-zoom-index-js" */),
  "component---src-pages-partners-reseller-js": () => import("./../../../src/pages/partners/reseller.js" /* webpackChunkName: "component---src-pages-partners-reseller-js" */),
  "component---src-pages-platform-admission-js": () => import("./../../../src/pages/platform/admission.js" /* webpackChunkName: "component---src-pages-platform-admission-js" */),
  "component---src-pages-platform-community-js": () => import("./../../../src/pages/platform/community.js" /* webpackChunkName: "component---src-pages-platform-community-js" */),
  "component---src-pages-platform-index-js": () => import("./../../../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-platform-learning-management-system-js": () => import("./../../../src/pages/platform/learning-management-system.js" /* webpackChunkName: "component---src-pages-platform-learning-management-system-js" */),
  "component---src-pages-platform-online-exams-js": () => import("./../../../src/pages/platform/online-exams.js" /* webpackChunkName: "component---src-pages-platform-online-exams-js" */),
  "component---src-pages-platform-online-live-js": () => import("./../../../src/pages/platform/online-live.js" /* webpackChunkName: "component---src-pages-platform-online-live-js" */),
  "component---src-pages-platform-school-management-js": () => import("./../../../src/pages/platform/school-management.js" /* webpackChunkName: "component---src-pages-platform-school-management-js" */),
  "component---src-pages-platform-student-information-system-js": () => import("./../../../src/pages/platform/student-information-system.js" /* webpackChunkName: "component---src-pages-platform-student-information-system-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-release-notes-index-js": () => import("./../../../src/pages/release-notes/index.js" /* webpackChunkName: "component---src-pages-release-notes-index-js" */),
  "component---src-pages-request-for-more-info-index-js": () => import("./../../../src/pages/request-for-more-info/index.js" /* webpackChunkName: "component---src-pages-request-for-more-info-index-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-videos-index-js": () => import("./../../../src/pages/resources/videos/index.js" /* webpackChunkName: "component---src-pages-resources-videos-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-solutions-college-and-university-js": () => import("./../../../src/pages/solutions/college-and-university.js" /* webpackChunkName: "component---src-pages-solutions-college-and-university-js" */),
  "component---src-pages-solutions-high-school-js": () => import("./../../../src/pages/solutions/high-school.js" /* webpackChunkName: "component---src-pages-solutions-high-school-js" */),
  "component---src-pages-solutions-middle-school-js": () => import("./../../../src/pages/solutions/middle-school.js" /* webpackChunkName: "component---src-pages-solutions-middle-school-js" */),
  "component---src-pages-solutions-primary-school-js": () => import("./../../../src/pages/solutions/primary-school.js" /* webpackChunkName: "component---src-pages-solutions-primary-school-js" */),
  "component---src-pages-solutions-test-center-js": () => import("./../../../src/pages/solutions/test-center.js" /* webpackChunkName: "component---src-pages-solutions-test-center-js" */),
  "component---src-pages-solutions-training-institute-js": () => import("./../../../src/pages/solutions/training-institute.js" /* webpackChunkName: "component---src-pages-solutions-training-institute-js" */),
  "component---src-pages-training-admin-index-js": () => import("./../../../src/pages/training/admin/index.js" /* webpackChunkName: "component---src-pages-training-admin-index-js" */),
  "component---src-pages-training-en-admin-index-js": () => import("./../../../src/pages/training-en/admin/index.js" /* webpackChunkName: "component---src-pages-training-en-admin-index-js" */),
  "component---src-pages-training-en-index-js": () => import("./../../../src/pages/training-en/index.js" /* webpackChunkName: "component---src-pages-training-en-index-js" */),
  "component---src-pages-training-en-instructor-index-js": () => import("./../../../src/pages/training-en/instructor/index.js" /* webpackChunkName: "component---src-pages-training-en-instructor-index-js" */),
  "component---src-pages-training-en-junior-index-js": () => import("./../../../src/pages/training-en/junior/index.js" /* webpackChunkName: "component---src-pages-training-en-junior-index-js" */),
  "component---src-pages-training-en-parent-index-js": () => import("./../../../src/pages/training-en/parent/index.js" /* webpackChunkName: "component---src-pages-training-en-parent-index-js" */),
  "component---src-pages-training-en-student-index-js": () => import("./../../../src/pages/training-en/student/index.js" /* webpackChunkName: "component---src-pages-training-en-student-index-js" */),
  "component---src-pages-training-index-js": () => import("./../../../src/pages/training/index.js" /* webpackChunkName: "component---src-pages-training-index-js" */),
  "component---src-pages-training-instructor-index-js": () => import("./../../../src/pages/training/instructor/index.js" /* webpackChunkName: "component---src-pages-training-instructor-index-js" */),
  "component---src-pages-training-junior-index-js": () => import("./../../../src/pages/training/junior/index.js" /* webpackChunkName: "component---src-pages-training-junior-index-js" */),
  "component---src-pages-training-parent-index-js": () => import("./../../../src/pages/training/parent/index.js" /* webpackChunkName: "component---src-pages-training-parent-index-js" */),
  "component---src-pages-training-student-index-js": () => import("./../../../src/pages/training/student/index.js" /* webpackChunkName: "component---src-pages-training-student-index-js" */)
}


import { getParameterByName } from "../utils/auth"

const storageType = typeof window !== `undefined` ? window.localStorage : {}

const setStorageComponent = (key, value) => {
  storageType.setItem(key, value)
}
export default setStorageComponent

export const getStorageComponent = key => storageType.getItem(key)

export const removeStorageComponent = key => storageType.removeItem(key)

export const getAccessToken = () => (typeof window !== `undefined` ? storageType.getItem("access_token") : "")

export const setUserInfo = value => storageType.setItem("userinfo", JSON.stringify(value))

export const getUserInfo = () => {
  const userinfo = storageType.getItem("userinfo") || ""
  if (userinfo) return JSON.parse(userinfo)
  return null
}

export const setUTMSource = () => {
  let UTMSource = {
    source: "direct",
    medium: "direct",
    content: "direct",
    campaign: "direct",
  }
  let localUTMSource = JSON.parse(localStorage.getItem("UTMSource")) || {}
  if (localUTMSource.source || getParameterByName("utm_source")) {
    if (
      (localUTMSource.source &&
        getParameterByName("utm_source") &&
        localUTMSource.source !== getParameterByName("utm_source")) ||
      (!localUTMSource.source && getParameterByName("utm_source"))
    ) {
      UTMSource.source = getParameterByName("utm_source")
      UTMSource.medium = getParameterByName("utm_medium")
      UTMSource.content = getParameterByName("utm_content")
      UTMSource.campaign = getParameterByName("utm_campaign")
      localStorage.setItem("UTMSource", JSON.stringify(UTMSource))
    }
  } else {
    localStorage.setItem("UTMSource", JSON.stringify(UTMSource))
  }
}
